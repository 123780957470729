import { formatCurrencyWithoutFractionDigits } from '@utils/helpers';
import { cn } from '@utils/utils';

interface AmountInvestedColumnProps {
  totalInvestedAmount: number;
  investedAmounts: number[];
  isChild: boolean;
  index: number;
}

const AmountInvestedColumn = ({
  totalInvestedAmount,
  index,
  investedAmounts,
  isChild,
}: AmountInvestedColumnProps) => {
  return (
    <span className={cn('text-primary-black')}>
      {isChild
        ? formatCurrencyWithoutFractionDigits(investedAmounts[index])
        : formatCurrencyWithoutFractionDigits(totalInvestedAmount)}
    </span>
  );
};

export default AmountInvestedColumn;
