import SvgLifeJacket from '@assets/icons/LifeJacket';
import {
  IconHome,
  IconCurrencyDollar,
  IconUsers,
  IconFileText,
  IconUserCircle,
  IconChevronRight,
} from '@tabler/icons-react';

enum NavLinkItemType {
  NORMAL = 'normal',
  EXPAND = 'expand',
  CUSTOM = 'custom',
  DIVIDER = 'divider',
}

type NavLink = { id: string } & (NormalLink | ExpandLink | DividerLink | CustomLink);

type NormalLink = {
  type: NavLinkItemType.NORMAL;
  label: string;
  icon?: React.FC<any>;
  link: string;
};

type CustomLink = {
  type: NavLinkItemType.CUSTOM;
  label: string;
  icon?: React.FC<any>;
  link?: string;
  onClick?: () => void;
};

type ExpandLink = {
  type: NavLinkItemType.EXPAND;
  label: string;
  icon?: React.FC<any>;
  links: SubLink[];
  expandIcon?: React.FC<any>;
};
type DividerLink = {
  type: NavLinkItemType.DIVIDER;
};

type SubLink = {
  label: string;
  link: string;
};

const NAV_LINKS: NavLink[] = [
  { id: 'home', type: NavLinkItemType.NORMAL, label: 'Home', icon: IconHome, link: '/home' },
  {
    id: 'my-portfolio',
    type: NavLinkItemType.NORMAL,
    label: 'My Portfolio',
    link: '/my-portfolio',
    icon: IconCurrencyDollar,
  },
  {
    id: 'member-directory',
    type: NavLinkItemType.NORMAL,
    label: 'Member Directory',
    icon: IconUsers,
    link: '/members',
  },
  {
    id: 'application-forms',
    type: NavLinkItemType.EXPAND,
    label: 'Forms',
    icon: IconFileText,
    links: [
      { label: 'Startup Submission Form', link: '/forms/startup-submission' },
      { label: 'Member Application Form', link: '/forms/member-application' },
    ],
    expandIcon: IconChevronRight,
  },
  {
    id: 'divider-1',
    type: NavLinkItemType.DIVIDER,
  },
  {
    id: 'help-support',
    label: 'Help & Support',
    icon: SvgLifeJacket,
    link: '/help',
    type: NavLinkItemType.NORMAL,
  },
  {
    id: 'my-profile',
    label: 'My Profile',
    icon: IconUserCircle,
    link: '/my-profile',
    type: NavLinkItemType.NORMAL,
  },
];

const SIDEBAR_EXPAND_WIDTH = 280;
const SIDEBAR_COLLAPSE_WIDTH = 80;
export { NAV_LINKS, NavLinkItemType, SIDEBAR_EXPAND_WIDTH, SIDEBAR_COLLAPSE_WIDTH };
export type { NavLink, NormalLink, ExpandLink, DividerLink, CustomLink, SubLink };
