import '@mantine/core/styles.css';
import './index.css';
import FeedbackFloatButton from '@components/Buttons/Feedback';
import { config } from '@constants/config.ts';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createRouter, RouterProvider } from '@tanstack/react-router';
import { lazy } from 'react';
import ReactGA from 'react-ga4';
import { Toaster } from 'react-hot-toast';
import { AppProvider } from './providers/AppContext.tsx';
import { AuthProvider, useAuth } from './providers/AuthContext.tsx';
import { routeTree } from './routeTree.gen';
import { queryClient } from './useQueries/queryClientConfig.ts';

// Create a new router instance
const router = createRouter({
  routeTree,
  defaultPreload: 'intent',
  context: {
    authentication: undefined!,
  },

  defaultNotFoundComponent: lazy(() => import('@components/pages/NotFound')),
  defaultErrorComponent: lazy(() => import('@components/Base/ErrorBoundary')),
});

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

function InnerApp() {
  const authentication = useAuth();

  return <RouterProvider router={router} context={{ authentication }} />;
}

export default function App() {
  ReactGA.initialize(config.GA_TRACKING_ID);

  return (
    <QueryClientProvider client={queryClient}>
      <AppProvider>
        <AuthProvider>
          <main className='relative flex flex-col h-screen max-h-screen'>
            <InnerApp />
            <FeedbackFloatButton />
          </main>
          <Toaster />
          <ReactQueryDevtools initialIsOpen={false} />
        </AuthProvider>
      </AppProvider>
    </QueryClientProvider>
  );
}
