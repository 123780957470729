interface InvestmentDateColumnProps {
  dates: string[];
}

const InvestmentDateColumn = ({ dates }: InvestmentDateColumnProps) => {
  if (!dates) {
    return <span>Not specified</span>;
  }

  return (
    <div className='flex flex-col gap-4'>
      {dates.map((date) => (
        <span key={date}>{date}</span>
      ))}
    </div>
  );
};

export default InvestmentDateColumn;
