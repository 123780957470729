import { useAuth } from '@provider/AuthContext';
import { useGetUserProfile } from '@queries/bases/xa-member-pipeline/useXaMembersQuery';

const useTermsOfUseAccepted = () => {
  const { user } = useAuth();

  const { data, isLoading, isRefetching, refetch } = useGetUserProfile(user?.['record id']);
  const isTermOfUseAccepted = data?.fields?.['Accepted Terms of Use'];

  return { isTermOfUseAccepted, isLoading, isRefetching, refetch };
};

export default useTermsOfUseAccepted;
