import { Breadcrumbs } from '@mantine/core';
import { useRouterState, Link } from '@tanstack/react-router';

type BreadCrumbProps = {
  className?: string;
  itemClassName?: string;
  seperatorClassName?: string;
};

const BreadCrumb = ({ className, itemClassName, seperatorClassName }: BreadCrumbProps) => {
  const {
    location: { pathname },
  } = useRouterState();
  const breadcrumbItems = pathname.split('/').filter((part: any) => part.trim() !== '') || [];

  return (
    <Breadcrumbs
      separator='/'
      classNames={{ breadcrumb: itemClassName, root: className, separator: seperatorClassName }}
    >
      {breadcrumbItems.length > 0 &&
        breadcrumbItems.map((item: string, index: number) => {
          if (index > 0 && pathname.includes('home')) {
            return (
              <Link
                key={index}
                to={pathname}
                activeProps={{
                  className:
                    '!text-darkest-red text-shadow-light-glow-red lg:text-shadow-dark-glow-red',
                }}
              >
                Investment Details
              </Link>
            );
          }

          if (index > 0 && pathname.includes('members')) {
            return (
              <Link key={index} to={pathname} activeProps={{ className: '!text-darkest-red' }}>
                Member Profile
              </Link>
            );
          }

          if (item === 'my-portfolio') {
            return (
              <Link key={index} to={pathname}>
                My Portfolio
              </Link>
            );
          }

          if (item === 'my-profile') {
            return (
              <Link key={index} to={pathname}>
                My Profile
              </Link>
            );
          }

          if (item === 'forms')
            return (
              <Link key={index} to={pathname}>
                Application Forms
              </Link>
            );

          if (item === 'startup-submission')
            return (
              <Link key={index} to={pathname} activeProps={{ className: '!text-darkest-red' }}>
                Startup Submission Form
              </Link>
            );

          if (item === 'member-application')
            return (
              <Link key={index} to={pathname} activeProps={{ className: '!text-darkest-red' }}>
                Member Application Form
              </Link>
            );

          return (
            <Link
              to={`/${breadcrumbItems.slice(0, index + 1).join('/')}`}
              href={`/${breadcrumbItems.slice(0, index + 1).join('/')}`}
              key={index}
            >
              {item.charAt(0).toUpperCase() + item.slice(1)}
            </Link>
          );
        })}
    </Breadcrumbs>
  );
};

export default BreadCrumb;
