import { axiosClient } from '@api/axiosClient';
import { QUERY_KEY } from '@constants/queryKeys';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

export type MemberData = {
  recordsById: Record<string, any>;
};

export function useGetUserProfile(userRecordId?: string) {
  return useQuery({
    queryKey: [QUERY_KEY.GET_MY_PROFILE, userRecordId],
    queryFn: async () => {
      const response = await axiosClient.get('/my-profile', {
        params: {
          userId: userRecordId,
        },
      });
      return await response.data;
    },
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    enabled: !!userRecordId,
    retry: 3,
  });
}

export function useGetMemberProfile(memberRecordId: string) {
  return useQuery({
    queryKey: [QUERY_KEY.GET_MEMBER_PROFILE, memberRecordId],
    queryFn: async () => {
      const response = await axiosClient.post('/member-profile', {
        userId: memberRecordId,
      });
      return await response.data;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });
}

export async function fetchAllLocations(): Promise<string[]> {
  const res = await axiosClient.get('/locations');
  return res.data;
}

export const useAllMembers = (): UseQueryResult<MemberData, Error> => {
  return useQuery<MemberData, Error>({
    queryKey: [QUERY_KEY.GET_ALL_XA_MEMBERS],
    queryFn: async () => {
      const res = await axiosClient.get('/members');
      return {
        recordsById: res.data as Record<string, any>,
      };
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};
