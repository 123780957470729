import { axiosClient } from '@api/axiosClient';
import { MUTATION_KEY } from '@constants/mutationKeys';
import { useMutation } from '@tanstack/react-query';

export function useLoginWithLinkedin() {
  return useMutation({
    mutationKey: [MUTATION_KEY.LOGIN_LINKEDIN],
    mutationFn: async (data: any) => {
      const response = await axiosClient.post('/auth/login/linkedin', data);
      return response;
    },
  });
}
