import { SIDEBAR_COLLAPSE_WIDTH } from '@constants/nav';
import { motion } from 'framer-motion';
import NavbarCollapsed from '../NavBars/NavbarCollapsed';

const CollapsedSidebar = () => {
  return (
    <motion.div
      initial={{ opacity: 0, x: -200 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -100 }}
      transition={{ duration: 0.2, ease: 'linear' }}
      className='fixed top-[60px] left-0 bottom-0'
      style={{ width: SIDEBAR_COLLAPSE_WIDTH }}
    >
      <NavbarCollapsed />
    </motion.div>
  );
};

export default CollapsedSidebar;
