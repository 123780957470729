import { Button, Group, Modal, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useSubmitFeedback } from '@queries/bases/xa-member-pipeline/useXaMembersMutation';
import { useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import { User } from '../../providers/AuthContext';

interface FeedBackModalProps {
  user: User;
  isOpen: boolean;
  onClose: () => void;
}

const MAX_FEEDBACK_LENGTH = 1000;

interface FeedbackFormValue {
  feedback: string;
}

export const FeedBackModal = ({ user, isOpen, onClose }: FeedBackModalProps) => {
  const [feedback, setFeedback] = useState('');
  const form = useForm<FeedbackFormValue>({
    mode: 'uncontrolled',
    initialValues: { feedback: '' },
    validate: {
      feedback: (value: string) =>
        value.length >= MAX_FEEDBACK_LENGTH
          ? `Whoops! You’ve exceeded the character limit. Please keep it within ${MAX_FEEDBACK_LENGTH} characters.`
          : null,
    },
    onValuesChange: (values) => {
      setFeedback(values.feedback);
    },
  });
  const { onSubmit, reset } = form;

  const { mutateAsync: sendFeedback, isPending } = useSubmitFeedback(user['record id']);

  const handleSubmitFeedback = (value: FeedbackFormValue) => {
    sendFeedback({ feedback: value.feedback })
      .then(() => {
        toast.success('Submit feedback successfully');
      })
      .catch((e) => {
        console.error(e);
        toast.error('Failed to submit feedback');
      })
      .finally(() => {
        handleClose();
      });
  };

  const handleClose = useCallback(() => {
    onClose();
    reset();
  }, [reset, onClose]);

  return (
    <Modal
      opened={isOpen}
      title={<p className='font-bold leading-6 tracking-wide'>Help Us Improve!</p>}
      onClose={handleClose}
      centered
      className='z-modal'
    >
      <div className='flex flex-col space-y-4'>
        <p className='text-base text-gray-500'>
          We're always looking to make XA Platform better for you. What can we do to enhance your
          experience?
        </p>
        <form className='flex flex-col' onSubmit={onSubmit(handleSubmitFeedback)}>
          <Textarea
            name='feedback'
            placeholder='Tell us more about your experience (optional)'
            maxLength={MAX_FEEDBACK_LENGTH}
            key={form.key('feedback')}
            {...form.getInputProps('feedback')}
          />
          <span className='text-[10px] text-gray-500 my-2'>
            {feedback.length} / {MAX_FEEDBACK_LENGTH} characters left
          </span>
          <Group display={'flex'} align='center' justify='end' style={{ gap: '1rem' }}>
            <Button className='px-4 py-2 ' variant='outline' onClick={handleClose} color='red'>
              Close
            </Button>
            <Button
              className='px-4 py-2 '
              variant='filled'
              type='submit'
              loading={isPending}
              color='red'
            >
              Submit feedback
            </Button>
          </Group>
        </form>
      </div>
    </Modal>
  );
};
