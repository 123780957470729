import { axiosClient } from '@api/axiosClient';
import { useMutation } from '@tanstack/react-query';

export function useEditMyPortfolioMyProfile(onSuccess?: () => void) {
  return useMutation({
    mutationKey: ['Edit_My_Portfolio_My_Profile'],
    mutationFn: async (data: any) => {
      const response = await axiosClient.patch('/my-portfolio/my-profile', data);
      return response;
    },
    onSuccess: () => {
      onSuccess && onSuccess();
    },
  });
}
export function useSubmitFeedback(userId?: string) {
  return useMutation({
    mutationKey: ['Submit_Feedback', userId],
    mutationFn: async (data: any) => {
      const response = await axiosClient.post('/feedback', data);
      return response;
    },
  });
}
